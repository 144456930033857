import { useState, useEffect } from 'react';

const parseCookies = (str = '') => str.split('; ').reduce((o, str) => {
  const [k, v] = str.split('=');
  return {
    ...o,
    [k]: v,
  };
}, {});

const useUserLocation = () => {
  const [userLocation, setUserLocation] = useState({
    countryCode: null,
  });

  useEffect(() => {
    const cookies = parseCookies(document.cookie);
    const { akamai_generated_location: akamiLocation } = cookies;

    if (!akamiLocation) return;
    // Akami will use """" for empty fields which is invalid JSON and will throw an error
    const cleanAkamiLocation = akamiLocation.replace(/""""/g, '""');
    const { countrycode: countryCode } = JSON.parse(cleanAkamiLocation);

    if (!countryCode) return;

    setUserLocation({
      ...userLocation,
      countryCode,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userLocation;
};

export default useUserLocation;


import React from 'react';
import { Helmet } from 'react-helmet';
import useBrandName from './useBrandName';
import BRANDS from './brands.json';

const FAVICON_HREF = {
  [BRANDS.FANDANGO]: '/favicon-fandango.png',
  [BRANDS.VUDU]: '/favicon-fah.ico',
  [BRANDS.ROTTEN_TOMATOES]: '/favicon-rottentomatoes.ico',
};

const BrandedFavicon = () => {
  const brand = useBrandName();

  if (!brand) return;

  return (
    <Helmet>
      <link rel="icon" href={FAVICON_HREF[brand]} />
    </Helmet>
  );
};

export default BrandedFavicon;

import React  from 'react';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import * as BrandedHeaders from './BrandedHeaders';
import useBrandName from './useBrandName';
import useQueryParams from './useQueryParams';
import useAPIHeaders from './useAPIHeaders';
import BrandedFavicon from './BrandedFavicon';
import IFrameResizer from './IFrameResizer';
import Hero from './Hero';
import PreferencesFormContainer from './PreferencesFormContainer';
import brands from './brands.json';
const FandangoTheme = React.lazy(() => import('./themes/fandango'));
const VuduTheme = React.lazy(() => import('./themes/vudu'));
const RottenTomatoesTheme = React.lazy(() => import('./themes/rottenTomatoes'));

const GRAPHQL_ENDPOINT = {
  [brands.VUDU]: process.env.REACT_APP_API_ENDPOINT_VUDU,
  [brands.ROTTEN_TOMATOES]: process.env.REACT_APP_API_ENDPOINT_ROTTEN_TOMATOES,
  [brands.FANDANGO]: process.env.REACT_APP_API_ENDPOINT_FANDANGO,
};

const HEADERS = {
  [brands.FANDANGO]: BrandedHeaders.Fandango,
  [brands.ROTTEN_TOMATOES]: BrandedHeaders.RottenTomatoes,
  [brands.VUDU]: BrandedHeaders.Vudu,
};

const ThemeProvider = ({ children, brand }) => {
  switch (brand) {
    case brands.FANDANGO:
      return (
        <FandangoTheme>
          {children}
        </FandangoTheme>
      );
    case brands.VUDU:
      return (
        <VuduTheme>
          {children}
        </VuduTheme>
      );
    case brands.ROTTEN_TOMATOES:
      return (
        <RottenTomatoesTheme>
          {children}
        </RottenTomatoesTheme>
      );
    default:
      return children
  }
};

const App = () => {
  const qs = useQueryParams();
  const brand = useBrandName();
  const apiHeaders = useAPIHeaders({ brand });
  const Header = HEADERS[brand];
  const endpoint = GRAPHQL_ENDPOINT[brand];
  const embedded = qs && qs.embedded === 'true';

  if (!brand) return '';

  const client = new ApolloClient({
    uri: endpoint,
    cache: new InMemoryCache(),
    credentials: 'include',
    headers: apiHeaders,
  });

  return (
    <ApolloProvider client={client}>
      <ThemeProvider brand={brand}>
        <CssBaseline />
        <BrandedFavicon />
        <IFrameResizer>
        {!embedded && (
          <Header />
        )}
          <Hero />
          <PreferencesFormContainer />
        </IFrameResizer>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;

import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from './Container';

const COPY = {
  TITLE: 'Fandango Media Brands Email Preference Center',
  BODY: 'Get updates on all things movies, entertainment, and more straight to your inbox from:'
};

const Hero = () => (
  <Container>
    <Grid container>
      <Grid item sm={12}>
        <div style={{ margin: '20px 0' }}>
          <Typography variant="h5"  sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}>{COPY.TITLE}</Typography>
          <Typography variant="subtitle">{COPY.BODY}</Typography>
        </div>
      </Grid>
    </Grid>
  </Container>
);

export default Hero;

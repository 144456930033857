import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingOverlay = ({ open }) => (
  <Backdrop
    sx={{ position: 'absolute' }}
    open={open}
  >
    <CircularProgress color="inherit" />
  </Backdrop>
);

export default LoadingOverlay;

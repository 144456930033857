import { useState, useEffect } from 'react';

const useQueryParams = () => {
  const [params, setParams] = useState();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const o = Object.fromEntries(urlSearchParams.entries());
    setParams(o);
  }, []);

  return params;
};

export default useQueryParams;

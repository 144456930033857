import React from 'react';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

export const CheckboxTitle = styled(Typography)({
  fontWeight: 'bold',
  fontSize: '1em',
  margin: 0,
});

const PreferencesFormControlLabel = ({
  onChange,
  checked,
  disabled,
  title,
  description,
}) => {
  return (
    <FormControlLabel onChange={onChange} disabled={disabled} checked={checked} control={<Checkbox sx={{ '& .MuiSvgIcon-root': { fontSize: 30 } }} />} label={
      <>
        <CheckboxTitle>{title}</CheckboxTitle>
        <Typography variant="body">{description}</Typography>
      </>
    } />
  );
};

export default PreferencesFormControlLabel;

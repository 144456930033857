import React from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import MUIDialog from '@mui/material/Dialog';
import DialogContentText from '@mui/material/DialogContentText';
import DialogContent from '@mui/material/DialogContent';

const Dialog = ({ onClose, open, title, body }) => (
  <MUIDialog onClose={onClose} open={open}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText variant="body">{body}</DialogContentText>
    </DialogContent>
  </MUIDialog>
);

export default Dialog;


import { useState, useEffect } from 'react';
import brands from './brands.json';

const useAPIHeaders = ({ brand }) => {
  const [apiHeaders, setApiHeaders] = useState({});

  useEffect(() => {
    if (brand !== brands.VUDU) return;

    const cookie = document.cookie;
    const cookieName = btoa('myvudu.userName');
    const cookies = cookie.split(/;\s?/).reduce((o, c) => {
      const [key, value] = c.split('=');
      return {
        ...o,
        [key]: value,
      };
    }, {});
    const session = cookies[cookieName];

    if (!session) return;

    setApiHeaders({
      Authorization: `Bearer ${session}`,
    });
  }, [brand]);

  return apiHeaders;
};

export default useAPIHeaders;

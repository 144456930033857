import React, { useEffect, useRef } from 'react';

const EVENTS = {
  ONLOAD: 'ONLOAD',
  RESIZE: 'RESIZE',
};

const debounce = (fn, timeout = 500) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => fn(...args), timeout);
  };
};

const IFrameResizer = ({ children }) => {
  const wrapRef = useRef('wrap');

  useEffect(() => {
    if (window !== window.parent) {
      const height = wrapRef.current.offsetHeight;
      window.parent.postMessage({ type: EVENTS.ONLOAD, height }, '*');

      window.addEventListener('resize', debounce(() => {
        const height = wrapRef.current.offsetHeight;
        window.parent.postMessage({ type: EVENTS.RESIZE, height }, '*');
      }));
    }
  }, [wrapRef]);

  return (
    <div ref={wrapRef}>
      {children}
    </div>
  )
};

export default IFrameResizer;

import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import Link from '@mui/material/Link';
import PreferencesFormControlLabel, { CheckboxTitle } from './PreferencesFormControlLabel';
import Container from './Container';
import brands from './brands.json';

const COPY = {
  FANDANGO: 'The ultimate destination for movie tickets, theater showtimes, trailers, and interviews.',
  VUDU: <>Buy, rent, or watch free movies on your favorite devices &mdash; <br /> at home or on the go &mdash; without subscription fees.</>,
  ROTTEN_TOMATOES: 'The world\'s most trusted source of movie and TV recommendations and reviews.',
  LEGAL: <>I understand and agree that by clicking the email customization option(s) above and clicking “SAVE PREFERENCES”, I agree to receive occasional email communications and consent to Fandango’s <Link target="_top" href="https://www.fandango.com/policies/privacy-policy">Privacy Policy</Link> and <Link target="_top" href="https://www.fandango.com/policies/terms-and-policies">Terms and Policies</Link>. Please allow 10 business days for your account to reflect your preferences.</>,
  UNSUBSCRIBE: 'Even if you choose not to receive any marketing emails, you will still receive our transactional emails such as email verifications and information about your account, purchases, and rentals. Please allow 10 business days for your account to reflect your preferences.',
  SUCCESS_NOTICE: 'Your email subscription has been updated.',
  SUCCESS_UNSUBSCRIBE: 'Your request to be unsubscribed has been received. Please allow 10 business days for your account to reflect the change.',
};

const LegalNotice = () => (
  <Grid container>
    <Grid item sm={12} md={8}>
      <div style={{ padding: '10px 0 10px 40px' }}>
        <Typography variant="body2">{COPY.LEGAL}</Typography>
      </div>
    </Grid>
  </Grid>
);

const SuccessNotice = () => (
  <Typography variant="body1">{COPY.SUCCESS_NOTICE}</Typography>
);

const PreferencesForm = ({ checked, onSubmit, onChange, onUnsubscribeAll, countryCode, brand, showSuccessNotice, showUnsubscribeSuccess }) => {
  const rtOnly = brand === brands.ROTTEN_TOMATOES && countryCode !== 'US';

  return (
    <Container>
      <form onSubmit={onSubmit} style={{ marginBottom: '15px' }}>
        <Grid container direction="column" spacing={2}>
          {!rtOnly && (
          <Grid item>
            <FormGroup>
              <PreferencesFormControlLabel
                onChange={e => onChange('fandango', e)}
                checked={checked.fandango}
                title="Fandango"
                description={COPY.FANDANGO}
              />
            </FormGroup>
          </Grid>
          )}
          {!rtOnly && (
          <Grid item>
            <FormGroup>
              <PreferencesFormControlLabel
                onChange={e => onChange('vudu', e)}
                checked={checked.vudu}
                title="Fandango at Home"
                description={COPY.VUDU}
              />
            </FormGroup>
          </Grid>
          )}
          <Grid item>
            <FormGroup>
              <PreferencesFormControlLabel
                onChange={e => onChange('rottenTomatoes', e)}
                checked={checked.rottenTomatoes}
                title="Rotten Tomatoes"
                description={COPY.ROTTEN_TOMATOES}
              />
            </FormGroup>
          </Grid>
          <Grid item>
            <LegalNotice />
            <Button type="submit" variant="brandedPrimary">Save Preferences</Button>
          </Grid>
        </Grid>
      </form>
    {showSuccessNotice && (
      <Grid container direction="column" style={{ marginBottom: '15px' }}>
        <Grid item>
          <SuccessNotice />
        </Grid>
      </Grid>
    )}
    <Divider style={{ marginBottom: '15px' }} />
    {!rtOnly && (
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Button onClick={onUnsubscribeAll} variant="text">Unsubscribe from all</Button>
          <CheckboxTitle>Unsubscribe From All</CheckboxTitle>
          <Typography>{COPY.UNSUBSCRIBE}</Typography>
        </Grid>
      </Grid>
    )}
    {showUnsubscribeSuccess && (
      <Grid container direction="column" style={{ margin: '15px 0' }}>
        <Grid item>
          <Typography variant="body1">{COPY.SUCCESS_UNSUBSCRIBE}</Typography>
        </Grid>
      </Grid>
    )}
    </Container>
  );
};

export default PreferencesForm;

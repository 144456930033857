import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import styled from '@emotion/styled';
import LoadingOverlay from './LoadingOverlay';
import useUserLocation from './useUserLocation';
import useQueryParams from './useQueryParams';
import useBrandName from './useBrandName';
import PreferencesForm from './PreferencesForm';
import Dialog from './Dialog';
import Survey from './Survey';
import {
  GET_USER,
  GET_VIEWER,
  UPDATE_VIEWER,
  UPDATE_USER,
} from './gqlQueries';

const GENERIC_ERROR_DIALOG = {
  title: 'Error',
  body: 'There was an error processing your requst. Please reload the page and try again.',
};

const Wrap = styled('div')`
  position: relative;
`;

const PreferencesFormContainer = () => {
  const params = useQueryParams();
  const client = useApolloClient();
  const brand = useBrandName();
  const { countryCode } = useUserLocation();
  const [{ loading: userLoading, user }, setUser] = useState({
    user: null,
    loading: false,
  });
  const [unsubscribeSuccess, setUnsubscribeSuccess] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [formState, setFormState] = useState({
    fandango: false,
    rottenTomatoes: false,
    vudu: false,
    unsubscribeAll: false,
  });
  const [updateLoading, setUpdateLoading] = useState(false);
  const [showSurvey, setShowSurvey] = useState(false);
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    body: '',
  });

  const handleDialogClose = () => {
    setDialog({
      open: false,
    });
  };

  const getChanges = (user, formState) => {
    const { fandango, vudu, rottenTomatoes } = user;

    return {
      fandango: [!!fandango?.list, formState.fandango],
      vudu: [!!vudu?.list, formState.vudu],
      rottenTomatoes: [!!rottenTomatoes?.list, formState.rottenTomatoes],
    };
  };

  const onChange = (k, e) => {
    const { target } = e;
    const { checked } = target;
    setFormState({
      ...formState,
      [k]: checked,
    });
    setUpdateSuccess(false);
    setUnsubscribeSuccess(false);
  };

  const onUnsubscribeAll = async (e) => {
    e.preventDefault();

    setUpdateSuccess(false)
    setUnsubscribeSuccess(false);
    setUpdateLoading(true);

    const { stid } = typeof params === 'object' ? params : {};
    const mutation = stid ? UPDATE_USER : UPDATE_VIEWER;
    const updateInput = {
      fandango: {
        lists: [{ where: { alias: 'PRIMARY' },  optedIn: false }],
      },
      vudu: {
        lists: [{ where: { alias: 'PRIMARY' },  optedIn: false }],
      },
      rottenTomatoes: {
        lists: [{ where: { alias: 'PRIMARY' },  optedIn: false }],
      },
    };

    const input = stid ? { where: { sailThruId: stid }, input: updateInput } : { input: updateInput };
    try {
      await client.mutate({
        mutation,
        variables: {
          ...input
        },
      });

      await getUser({ stid });
      setUnsubscribeSuccess(true);
      setUpdateLoading(false);
      setShowSurvey(true);
    } catch (e) {
      setDialog({
        open: true,
        ...GENERIC_ERROR_DIALOG,
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setUpdateSuccess(false)
    setUnsubscribeSuccess(false);
    setUpdateLoading(true);

    const { stid } = typeof params === 'object' ? params : {};
    const mutation = stid ? UPDATE_USER : UPDATE_VIEWER;
    const changes = getChanges(user, formState);
    const updateInput = {};

    if (changes.fandango[0] !== changes.fandango[1]) {
      updateInput.fandango = {
        lists: [{ where: { alias: 'PRIMARY' },  optedIn: changes.fandango[1] }],
      };
    }

    if (changes.vudu[0] !== changes.vudu[1]) {
      updateInput.vudu = {
        lists: [{ where: { alias: 'PRIMARY' },  optedIn: changes.vudu[1] }],
      };
    }

    if (changes.rottenTomatoes[0] !== changes.rottenTomatoes[1]) {
      updateInput.rottenTomatoes = {
        lists: [{ where: { alias: 'PRIMARY' },  optedIn: changes.rottenTomatoes[1] }],
      };
      if (countryCode) updateInput.rottenTomatoes.countryCode = countryCode;
    }

    const input = stid ? { where: { sailThruId: stid }, input: updateInput } : { input: updateInput };
    try {
      await client.mutate({
        mutation,
        variables: {
          ...input
        },
      });

      await getUser({ stid });
      setUpdateSuccess(true)
      setShowSurvey(true);
    } catch (e) {
      setDialog({
        open: true,
        ...GENERIC_ERROR_DIALOG,
      });
    }

    setUpdateLoading(false);
  };

  useEffect(() => {
    if (!user) return;

    const { fandango, vudu, rottenTomatoes } = user;

    setFormState({
      fandango: !!fandango?.list,
      vudu: !!vudu?.list,
      rottenTomatoes: !!rottenTomatoes?.list,
      unsubscribeAll: false,
    });
  }, [user]);

  const getUser = async ({ stid }) => {
    setUser({ loading: true });

    try {
      if (stid) {
        const { data } = await client.query({
          query: GET_USER,
          fetchPolicy: 'no-cache',
          variables: {
            sailThruId: stid,
          },
        });
        const { user } = data;
        setUser({ user, loading: false });
      } else {
        const { data } = await client.query({
          query: GET_VIEWER ,
          fetchPolicy: 'no-cache',
        });
        const { viewer } = data;
        setUser({ user: viewer, loading: false });
      }
    } catch (e) {
      setDialog({
        open: true,
        ...GENERIC_ERROR_DIALOG,
      });
    }
  };

  useEffect(() => {
    if (typeof params !== 'object') return;
    const { stid } = params;

    getUser({ stid });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, client]);

  return (
    <>
      <Wrap>
        <PreferencesForm
          checked={formState}
          onChange={onChange}
          onUnsubscribeAll={onUnsubscribeAll}
          onSubmit={onSubmit}
          countryCode={countryCode}
          brand={brand}
          showSuccessNotice={updateSuccess}
          showUnsubscribeSuccess={unsubscribeSuccess}
        />
        <Dialog onClose={handleDialogClose} open={dialog.open} title={dialog.title} body={dialog.body} />
        <LoadingOverlay open={userLoading || updateLoading} />
      </Wrap>
      {showSurvey && (
        <Survey />
      )}
    </>
  );
};

export default PreferencesFormContainer;

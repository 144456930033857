import { gql } from '@apollo/client';

export const GET_USER = gql`
  query user($sailThruId: ID!) {
    user(where: { sailThruId: $sailThruId }) {
      email
      fandango {
        list(where: { alias: PRIMARY }) {
          name
          optInDate
        }
      }
      rottenTomatoes {
        list(where: { alias: PRIMARY }) {
          name
          optInDate
        }
        countryCode
      }
      vudu {
        list(where: { alias: PRIMARY }) {
          name
          optInDate
        }
      }
    }
  }
`;

export const GET_VIEWER = gql`
  query viewer {
    viewer {
      email
      fandango {
        list(where: { alias: PRIMARY }) {
          name
          optInDate
        }
      }
      rottenTomatoes {
        list(where: { alias: PRIMARY }) {
          name
          optInDate
        }
        countryCode
      }
      vudu {
        list(where: { alias: PRIMARY }) {
          name
          optInDate
        }
      }
    }
  }
`;

export const UPDATE_VIEWER = gql`
  mutation updateViewer($input: UpdateUserInput!) {
    updateViewer(input: $input) {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($where: UserSearch!, $input: UpdateUserInput!) {
    updateUser(where: $where, input: $input) {
      success
    }
  }
`;

import { useEffect, useState } from 'react';
import BRANDS from './brands.json';

const DEFAULT_BRAND = BRANDS.FANDANGO;
const DOMAINS = {
  fandango: 'fandango.com',
  rottenTomatoes: 'rottentomatoes.com',
  vudu: 'vudu.com',
  marquee: 'marquee.net',
};
const VALID_DOMAINS = Object.values(DOMAINS);

const BRAND_FOR_DOMAIN = {
  [DOMAINS.fandango]: BRANDS.FANDANGO,
  [DOMAINS.rottenTomatoes]: BRANDS.ROTTEN_TOMATOES,
  [DOMAINS.vudu]: BRANDS.VUDU,
  [DOMAINS.marquee]: BRANDS.VUDU,
};

const useBrandName = () => {
  const [brand, setBrand] = useState();

  useEffect(() => {
    const { hostname } = window.location;
    const parts = hostname.split('.');
    const domain = parts.slice(-2, parts.length).join('.');
    const brand = VALID_DOMAINS.includes(domain)
      ? BRAND_FOR_DOMAIN[domain]
      : DEFAULT_BRAND;

    setBrand(brand);
  }, [brand]);

  return brand;
};

export default useBrandName;

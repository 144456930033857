import React from 'react';
import Grid from '@mui/material/Grid';
import styled from '@emotion/styled';
import Container from './Container';
import { ReactComponent as LogoFandango } from './svg/logo-fandango.svg';
import { ReactComponent as LogoVudu } from './svg/logo-fandango-at-home-white.svg';
import { ReactComponent as LogoRottenTomatoes } from './svg/logo-rottentomatoes-white.svg';
import brands from './brands.json';

const LOGOS = {
  [brands.FANDANGO]: () => <LogoFandango height="60" style={{ verticalAlign: 'middle' }} />,
  [brands.ROTTEN_TOMATOES]: () => <LogoRottenTomatoes height="50" style={{ verticalAlign: 'middle' }} />,
  [brands.VUDU]: () => <LogoVudu height="50" style={{ verticalAlign: 'middle' }} />,
};

const BACKGROUND = {
  [brands.FANDANGO]: '#171C20',
  [brands.ROTTEN_TOMATOES]: '#FA320A',
  [brands.VUDU]: 'linear-gradient(90deg,#3478c1,#104d75 100%)',
};

const Background = styled.div(props => ({
  lineHeight: '80px',
  background: BACKGROUND[props.brand],
}))

const BrandedHeader = ({ brand }) => {
  const Logo = LOGOS[brand];

  return (
    <Background brand={brand}>
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <Logo />
          </Grid>
        </Grid>
      </Container>
    </Background>
  );
};

const Fandango = () => <BrandedHeader brand={brands.FANDANGO} />;
const RottenTomatoes = () => <BrandedHeader brand={brands.ROTTEN_TOMATOES} />; 
const Vudu = () => <BrandedHeader brand={brands.VUDU} />

export {
  Fandango,
  RottenTomatoes,
  Vudu,
};
